<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">Setting</h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Setting</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <section id="nav-filled">
                  <div class="row match-height">
                    <div class="col-xl-12 col-lg-12">
                      <div class="card">
                        <div class="card-body">
                          <!-- Nav tabs -->
                          <ul
                            class="nav nav-tabs nav-fill setting"
                            id="myTab"
                            role="tablist"
                          >
                            <template
                              v-for="(head, index) in header"
                              :key="index"
                            >
                              <li class="nav-item">
                                <a
                                  class="nav-link"
                                  :class="index == 0 ? `active` : ``"
                                  :id="head.id"
                                  data-bs-toggle="tab"
                                  :href="`#` + head.href"
                                  role="tab"
                                  :aria-controls="head.href"
                                  aria-selected="true"
                                  >{{ head.name }}</a
                                >
                              </li>
                            </template>
                          </ul>
                          <!-- Tab panes -->
                          <div class="tab-content pt-1">
                            <div
                              v-for="(head, index) in header"
                              :key="index"
                              class="tab-pane"
                              :class="index == 0 ? `active` : ``"
                              :id="head.href"
                              role="tabpanel"
                              :aria-labelledby="head.id"
                            >
                              <form
                                class="add-new-user modal-content pt-0"
                                @submit.prevent="submitForm"
                                v-show="head.name == `Setting`"
                              >
                                <div class="flex-grow-1">
                                  <div class="col-md-6 col-sm-12">
                                    <div
                                      class="mb-1"
                                      v-for="(li, index) in form.settingData"
                                      :key="li.id"
                                    >
                                      <div
                                        v-if="
                                          ![
                                            'festival_price',
                                            'theater_people_limit',
                                          ].includes(li.key)
                                        "
                                      >
                                        <label
                                          class="form-label toCapitalFirst"
                                          for="basic-addon-name"
                                          >{{
                                            li.key.replaceAll("_", " ")
                                          }}</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control toCapitalFirst"
                                          name="value"
                                          :class="
                                            li.value ? `placeholderColor` : ``
                                          "
                                          v-model="
                                            form.settingData[index].value
                                          "
                                          :placeholder="
                                            li.value
                                              ? li.value
                                              : li.key.replaceAll('_', ' ')
                                          "
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    type="submit"
                                    class="btn btn-primary me-1 data-submit"
                                  >
                                    Submit
                                  </button>
                                  <button
                                    type="reset"
                                    class="btn btn-outline-secondary"
                                    data-bs-dismiss="modal"
                                    @click="
                                      [(isShow = ''), (isDisplay = 'none')]
                                    "
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";

export default {
  name: "Setting",
  data() {
    return {
      form: new Form({
        id: null,
        settingData: [],
      }),
      response: "",
      header: [],
      token: localStorage.getItem("auth_token"),
    };
  },
  async mounted() {
    this.header = [
      { name: "Setting", id: "setting-tab-fill", href: "setting-fill" },
      { name: "Festival", id: "festival-tab-fill", href: "festival-fill" },
    ];
    this.getResults();
  },
  methods: {
    async getResults() {
      await axios
        .get(process.env.VUE_APP_API_URL + "/setting", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.form.settingData = response.data.data;
        });
    },
    async submitForm() {
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/setting",
          {
            setting_data: this.form.settingData,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.$toast.success(response.data.message);
            this.disabled = false;
            this.settingData = response.data.data;
          } else {
            this.$toast.error(response.data.error?.[0]);
            this.disabled = false;
          }
        })
        .catch((error) => {
          this.$toast.error(error);
          this.disabled = false;
        });
    },
  },
};
</script>
<style>
.setting li.nav-item:hover {
  background-color: rgb(125 167 65 / 7%) !important;
  box-shadow: 0 8px 10px -8px #7da741;
  /* background-color: rgba(115, 103, 240, 0.07) !important;
    box-shadow: 0 8px 10px -8px #958cf1; */
}
.toCapitalFirst {
  text-transform: capitalize;
}
.placeholderColor::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #6e6b7b !important;
  opacity: 1; /* Firefox */
}

.placeholderColor:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6e6b7b !important;
}

.placeholderColor::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6e6b7b !important;
}
</style>
